import React, { useEffect, useState } from 'react';
import { Image, Tab, Tabs } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Banner2 from '../components/Banner2';
import CasesBanner from '../images/cases-banner-2.svg';
import Layout from '../components/Layout';
import TitledBoxesWithBg from '../components/TitledBoxesWithBg';
import CasesPain1 from '../images/cases-pain-1.svg';
import CasesPain2 from '../images/cases-pain-2.svg';
import CasesPain3 from '../images/cases-pain-3.svg';
import CasesPain4 from '../images/cases-pain-4.svg';
import StepSolutions from '../components/StepSolutions';
import Step11 from '../images/step-1-1.svg';
import Step12 from '../images/step-1-2.svg';
import Step13 from '../images/step-1-3.svg';
import Step14 from '../images/step-1-4.svg';
import Step1 from '../images/step_1_1.svg';
import Step2 from '../images/step_1_2.svg';
import Step3 from '../images/step_1_3.svg';
import Step4 from '../images/step_1_4.svg';
import StepsTopper from '../images/steps-topper-1.png';

const boxDataList = [
  {
    title: '难以评估线索质量',
    content: `难以掌握客户全貌，难以准确甄别客户需求;\n缺乏细分客户的能力`,
    icon: CasesPain1,
    id: 'cases-1',
    bgColor: 'white',
  },
  {
    title: '缺乏高效的线索培育手段',
    content: `市场销售衔接断层，未能覆盖客户全生命周期;\n缺乏能多次持续触达客户的渠道`,
    icon: CasesPain2,
    id: 'cases-2',
    bgColor: 'yellow',
  },
  {
    title: '难以提升市场活动效率',
    content: `很难统一管理活动内容、物料、流程和客户群;\n活动后难以最大化利用活动数据池`,
    icon: CasesPain3,
    id: 'cases-3',
    bgColor: 'yellow',
  },
  {
    title: '与销售/经销商的协同不足',
    content: `数据流通性不强，销售/经销商难以及时了解客户;\n销售/经销商辅助工具包难以实时更新和同步`,
    icon: CasesPain4,
    id: 'cases-4',
    bgColor: 'white',
  },
];

const TabTitle = ({ title }) => {
  return <span className="title-content">{title}</span>;
};

TabTitle.propTypes = {
  title: PropTypes.string,
};

let timer = null;

function StepImage1() {
  const [activeKey, setActiveKey] = useState('1');
  // 自动循环+点击切换
  useEffect(() => {
    timer = setInterval(() => {
      setActiveKey((_current) => {
        const newKey = parseInt(_current, 10) + 1;
        return newKey > 4 ? '1' : `${newKey}`;
      });
    }, 1000 * 5);
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  return (
    <div className="step-1-image-container">
      <Image src={Step1} className="img-fluid" />
      <div className="tabs-wrapper">
        <Tabs activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
          <Tab eventKey="1" title={<TabTitle title="线索身份" />}>
            <Image src={Step11} className="img-fluid" />
          </Tab>
          <Tab eventKey="2" title={<TabTitle title="来源信息" />}>
            <Image src={Step12} className="img-fluid" />
          </Tab>
          <Tab eventKey="3" title={<TabTitle title="标签&群组" />}>
            <Image src={Step13} className="img-fluid" />
          </Tab>
          <Tab eventKey="4" title={<TabTitle title="营销动态" />}>
            <Image src={Step14} className="img-fluid" />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

const stepData = {
  titles: [
    `全面接入客户数据，\n构建360°客户画像`,
    `构建私域流量池，\n与客户建立长效沟通`,
    `统一看板管理营销活动，\n洞察效果数据`,
    `移动端协同系统，\n市场销售数据双向打通`,
  ],
  images: [<StepImage1 />, Step2, Step3, Step4],
  contents: [
    [
      '我们提供多渠道的客户属性和营销事件接入，并为客户创建对应的渠道身份，记录客户来源信息。',
      '企业可根据客户的属性和事件，为客户贴标签、分群组、打分、设定线索阶段等。也可根据客户阅读内容的类型、频次，标记客户的内容偏好。',
      '这样，企业就可全面了解客户与企业有哪些触点渠道，信息是否完整，喜欢阅读哪些类型的内容等。',
      '从而对客户的需求和偏好有一个全面的了解。',
    ],
    [
      '微信生态具有高粘性和可重复触达的特质，是企业培育客户的重要阵地。我们深入对接了微信公众号和企业微信，帮助企业构建私域流量池。',
      '同时，我们也为企业提供自定义客户阶段的能力，企业可以配置客户的进阶规则、负责人以及内容。',
      '结合Step1中对客户的了解，我们能自动化地投递客户喜欢的内容，或符合其所在阶段的内容。',
      '同时，我们将为客户的每一次互动记录分值，从而帮助企业更好地培育客户，引导客户进入下一阶段。',
    ],
    [
      '我们为企业提供了非常灵活的活动统计看板，企业可以通过“托拉拽”不同的活动素材，来组件自己的看板。同时，企业也可以按照活动流程、素材类型或其他逻辑，任意分组。',
      '活动结束后，企业可以利用会议文档、图文、调研表单等多重手段，去促进留资和判断客户的沟通意向。',
      '我们为企业提供包含参与人数、报名来源统计、新客来源统计、报名率、签到率等全面的活动数据报表，为下一次活动优化提供数据依据。',
    ],
    [
      '我们在微信生态上构建了移动端协同系统，当一条高质量线索被分配到销售时，他们收到企业微信消息或微信模板消息提醒。',
      '销售可以点击该条信息查看线索详情，客户的每一条动态、每一个信息都会实时同步至移动端系统上。',
      '同时，我们在移动端系统上也同步了销售内容库，市场所更新的内容也将实时展示。销售可将内容轻松转发给客户，并追踪内容后续的阅读、点击数据。',
      '大大降低了市场与销售协同的门槛和时间成本。',
    ],
  ],
};

export default () => (
  <Layout current="solutions" subCurrent="manufacturing">
    <Banner2
      title="工业制造行业"
      Ptitle3="掌握线索全貌，提升营销效率"
      backgroundImage={CasesBanner}
      showDemoBtn
      buttonText="咨询行业方案"
    />
    <TitledBoxesWithBg
      title="营销痛点与挑战"
      description="工业制造行业的获客渠道多、产品品类多、营销周期长，企业往往很难了解客户全貌，且销售和经销商体系庞大。以上营销特性，会产生以下营销问题："
      boxDataList={boxDataList}
    />
    <StepSolutions data={stepData} stepsTopper={StepsTopper} />
  </Layout>
);
